import React from 'react';
import img01 from '../../upload/hom3_morph_bg2.png';
const Quotes = () => {
	return (
		<section
			className="elementor-element elementor-element-df31f43 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-id="df31f43"
			data-element_type="section"
		>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-87f09a8 elementor-column elementor-col-100 elementor-top-column"
						data-id="87f09a8"
						data-element_type="column"
						data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-c60229b elementor-widget__width-inherit elementor-absolute elementor-widget elementor--widget--image"
									data-id="c60229b"
									data-element_type="widget"
									data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="image.default"
								>
									{/* <div className="elementor-widget-container">
										<div className="elementor-image">
											<img
												width={747}
												height={704}
												src={img01}
												className="attachment-full size-full"
												alt=""
											/>
										</div>
									</div> */}
								</div>
								<div
									className="elementor-element elementor-element-26845b4 elementor-widget__width-inherit elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
									data-id="26845b4"
									data-element_type="widget"
									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="heading.default"
								>
									<div className="elementor-widget-container">
										<h2 className="elementor-heading-title elementor-size-default" id="quotes-font-size">
											<br />
											Pharma Metric Lab is the leading CRO that will give you
										</h2>
										<h2 className="elementor-heading-title elementor-size-default" id="quotes-font-size">
											better solution, experience, and result
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Quotes;
