import React from 'react';
import test1 from '../../../upload/branding_home1.jpg';
import test2 from '../../../upload/branding_home2.jpg';
import ImgComponent from '../imgComponent';

const Banner = () => {
	return (
		// <Body>
		<section
			className="elementor-element elementor-element-fb7667e elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-element_type="section"
			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;shape_divider_bottom&quot;:&quot;curve&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;}"
		>
			<div className="elementor-shape elementor-shape-bottom" data-negative="true">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
					<path
						className="elementor-shape-fill"
						d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
					/>
				</svg>
			</div>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-badcbdc elementor-column elementor-col-100 elementor-top-column"
						data-element_type="column"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-7bef6e7 elementor-widget elementor-widget-avante-slider-parallax"
									data-element_type="widget"
									data-widget_type="avante-slider-parallax.default"
								>
									<div className="elementor-widget-container">
										<div
											className="slider-parallax-wrapper"
											data-autoplay={8000}
											data-pagination={0}
											data-navigation={1}
										>
											<div className="slider_parallax_inner">
												<div className="slider_parallax_slides">
													<div className="slide is-active ">
														<div className="slide-content align-center">
															<div className="standard-wrapper">
																<div className="caption">
																	<div className="title title-banner">
																		<h2>Accelerating Research</h2>
																		<h2>Forward</h2>
																	</div>
																	<div className="text title-banner-bawah">
																		We are a world-class contract research
																		organization
																	</div>
																	<a
																		className="button button-color"
																		href="/AboutUs"
																		id="warnaButton"
																	>
																		Find Out More{' '}
																	</a>
																</div>
															</div>
														</div>
														<div className="image-container">
															<ImgComponent
																pmlImg="branding_home1.jpg"
																className="image"
															/>
														</div>
													</div>
													<div className="slide ">
														<div className="slide-content align-center">
															<div className="standard-wrapper">
																<div className="caption">
																	<div className="title title-banner">
																		<h2>High Quality,</h2>
																		<h2>Best Price</h2>
																	</div>
																	<div className="text title-banner-bawah">
																		Home of full range contract services with
																		world-wide accreditation
																	</div>
																	<a
																		className="button button-color"
																		href="/AboutUs"
																		id="warnaButton"
																	>
																		Find Out More{' '}
																	</a>
																</div>
															</div>
														</div>
														<div className="image-container">
															<ImgComponent
																pmlImg="branding_home2.jpg"
																className="image"
															/>
														</div>
													</div>
												</div>
												<div className="arrows">
													<div className="arrow prev">
														<span className="svg svg-arrow-left">
															<svg
																version="1.1"
																id="svg4-Layer_1"
																xmlns="http://www.w3.org/2000/svg"
																xmlnsXlink="http://www.w3.org/1999/xlink"
																x="0px"
																y="0px"
																width="14px"
																height="26px"
																viewBox="0 0 14 26"
																enableBackground="new 0 0 14 26"
																xmlSpace="preserve"
															>
																<path d="M13,26c-0.256,0-0.512-0.098-0.707-0.293l-12-12c-0.391-0.391-0.391-1.023,0-1.414l12-12c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L2.414,13l11.293,11.293c0.391,0.391,0.391,1.023,0,1.414C13.512,25.902,13.256,26,13,26z" />
															</svg>
															<span className="alt sr-only" />
														</span>
													</div>
													<div className="arrow next">
														<span className="svg svg-arrow-right">
															<svg
																version="1.1"
																id="svg5-Layer_1"
																xmlns="http://www.w3.org/2000/svg"
																xmlnsXlink="http://www.w3.org/1999/xlink"
																x="0px"
																y="0px"
																width="14px"
																height="26px"
																viewBox="0 0 14 26"
																enableBackground="new 0 0 14 26"
																xmlSpace="preserve"
															>
																<path d="M1,0c0.256,0,0.512,0.098,0.707,0.293l12,12c0.391,0.391,0.391,1.023,0,1.414l-12,12c-0.391,0.391-1.023,0.391-1.414,0s-0.391-1.023,0-1.414L11.586,13L0.293,1.707c-0.391-0.391-0.391-1.023,0-1.414C0.488,0.098,0.744,0,1,0z" />
															</svg>
															<span className="alt sr-only" />
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		// </Body>
	);
};

export default Banner;
