import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const InstagramSlider = ({ contents }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Slider
        {...settings}
        className="slider-content btn-style-1"
        style={{ paddingBottom: "4em", marginBottom: '2em' }}
      >
        {contents.map((post) => {
          const { localImage, permalink, caption } = post
          const pathToImage = getImage(localImage)
          return (
            <>
              <a href={permalink} target="_blank" rel="noreferrer">
                <GatsbyImage
                  image={pathToImage}
                  alt={caption}
                  className="instagram-image"
                />
              </a>
            </>
          )
        })}
      </Slider>
    </>
  )
}

export default InstagramSlider
