import React from 'react';
import ImgComponent from '../imgComponent';

const goalsContent = () => {
	return (
    <section
      className="elementor-element elementor-element-64d9982 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="64d9982"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-2c2aa61 elementor-column elementor-col-100 elementor-top-column"
            data-id="2c2aa61"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <section
                  className="elementor-element elementor-element-82e4d99 elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                  data-id="82e4d99"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-element elementor-element-5bc299e elementor-column elementor-col-50 elementor-inner-column"
                        data-id="5bc299e"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated padding-home-business">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-3e9c73c elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading"
                              data-id="3e9c73c"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_infinite":"false"}'
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  Our Business
                                </h2>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a380d98 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                              data-id="a380d98"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  Providing Full Range of Contract Services
                                </h2>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-e2ea58f elementor-widget elementor-widget-accordion"
                              data-id="e2ea58f"
                              data-element_type="widget"
                              data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_scalex":{"unit":"px","size":0.9,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.9,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":60,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                              data-widget_type="accordion.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  className="elementor-accordion our-business-width"
                                  role="tablist"
                                >
                                  <div className="elementor-accordion-item">
                                    <h6
                                      id="elementor-tab-title-2371"
                                      className="elementor-tab-title"
                                      data-tab={1}
                                      role="tab"
                                      aria-controls="elementor-tab-content-2371"
                                    >
                                      <span
                                        className="elementor-accordion-icon elementor-accordion-icon-right"
                                        aria-hidden="true"
                                      >
                                        <span className="elementor-accordion-icon-closed">
                                          <i className="fas fa-angle-down" />
                                        </span>
                                        <span className="elementor-accordion-icon-opened">
                                          <i className="fas fa-angle-up" />
                                        </span>
                                      </span>
                                      <div className="font-size-title-goals">
                                        <a href>
                                          Bioavailability/ Bioequivalence
                                          Studies
                                        </a>
                                      </div>
                                    </h6>
                                    <div
                                      id="elementor-tab-content-2371"
                                      className="elementor-tab-content elementor-clearfix"
                                      data-tab={1}
                                      role="tabpanel"
                                      aria-labelledby="elementor-tab-title-2371"
                                    >
                                      <p className="p1 goals-font">
                                        We provide our BA/BE service according
                                        to Good Clinical Practice and we comply
                                        to ISO 17025/GLP. Currently we have 350
                                        completed and 250 ongoing studies in the
                                        field of analgesic, antiviral,
                                        antidiabetic, antibiotic,
                                        immunosupressant, and many more. Our
                                        customers come from Indonesian and
                                        International pharmaceutical and
                                        biotechnology companies. Our reports
                                        have been accepted by FDA in certain
                                        countries.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="elementor-accordion-item">
                                    <h6
                                      id="elementor-tab-title-2372"
                                      className="elementor-tab-title"
                                      data-tab={2}
                                      role="tab"
                                      aria-controls="elementor-tab-content-2372"
                                    >
                                      <span
                                        className="elementor-accordion-icon elementor-accordion-icon-right"
                                        aria-hidden="true"
                                      >
                                        <span className="elementor-accordion-icon-closed">
                                          <i className="fas fa-angle-down" />
                                        </span>
                                        <span className="elementor-accordion-icon-opened">
                                          <i className="fas fa-angle-up" />
                                        </span>
                                      </span>
                                      <div className="font-size-title-goals">
                                        <a href>Clinical Trial</a>
                                      </div>
                                    </h6>
                                    <div
                                      id="elementor-tab-content-2372"
                                      className="elementor-tab-content elementor-clearfix"
                                      data-tab={2}
                                      role="tabpanel"
                                      aria-labelledby="elementor-tab-title-2372"
                                    >
                                      <p className="p1 goals-font">
                                        We offer in-house clinical trials with
                                        reputable investigators and we have
                                        collaborations with various hospitals
                                        and universities in Indonesia, such as:
                                        RS Cipto Mangunkusumo, RSUD Dr. Soetomo,
                                        RS Persahabatan, RS Medistra, University
                                        of Indonesia, Institut Teknologi
                                        Bandung, Universitas Padjajaran, and
                                        many more.
                                      </p>
                                      <div className="p1 goals-font">
                                        We currently have 40 completed and 13
                                        ongoing trials in the field of oncology,
                                        nutrition, neurology, immunomodulator,
                                        hormone, and many more for both local
                                        and international customers.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="elementor-accordion-item">
                                    <h6
                                      id="elementor-tab-title-2373"
                                      className="elementor-tab-title"
                                      data-tab={3}
                                      role="tab"
                                      aria-controls="elementor-tab-content-2373"
                                    >
                                      <span
                                        className="elementor-accordion-icon elementor-accordion-icon-right"
                                        aria-hidden="true"
                                      >
                                        <span className="elementor-accordion-icon-closed">
                                          <i className="fas fa-angle-down" />
                                        </span>
                                        <span className="elementor-accordion-icon-opened">
                                          <i className="fas fa-angle-up" />
                                        </span>
                                      </span>
                                      <div className="font-size-title-goals">
                                        <a href>Contract Analysis</a>
                                      </div>
                                    </h6>
                                    <div
                                      id="elementor-tab-content-2373"
                                      className="elementor-tab-content elementor-clearfix"
                                      data-tab={3}
                                      role="tabpanel"
                                      aria-labelledby="elementor-tab-title-2373"
                                    >
                                      <p className="p1 goals-font">
                                        We have provided 1400 completed and 150
                                        ongoing contract analysis services in
                                        the field of chemical, microbiological,
                                        biomolecular, physical/packaging
                                        testing, and many more for both local
                                        and international customers
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-4ffd281 elementor-column elementor-col-50 elementor-inner-column"
                        data-id="4ffd281"
                        data-element_type="column"
                        data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-b8c0a82 elementor-widget__width-initial elementor-absolute animation mobile_static elementor-widget-tablet__width-initial elementor-widget elementor--widget--image"
                              data-id="b8c0a82"
                              data-element_type="widget"
                              data-settings='{"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"}'
                              data-widget_type="image.default"
                              id="positionGoalContent"
                            >
                              <div className="elementor-widget-container home-image-width">
                                <div className="elementor-image">
                                  <ImgComponent
                                    pmlImg="HomeAbout.png"
                                    className="attachment-full size-full imgEmir"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default goalsContent;
