import React from "react"
// import Logo from '../upload/logo_white.png';
import Logo from "../../../upload/logoPML.png"
import img1 from "../../../upload/NavBabe.png"
import img2 from "../../../upload/NavCA.png"
import img3 from "../../../upload/NavCT.png"
import img4 from "../../../upload/NavOT.png"
import { AiOutlineBars } from "react-icons/ai"
import { ArrowRightCircle } from "react-feather"

const Navbar = () => {
  return (
    <section
      class="elementor-element elementor-element-4398f8f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div class="elementor-container elementor-column-gap-no">
        <div class="elementor-row">
          <div
            class="elementor-element elementor-element-f49fd9c elementor-column elementor-col-50 elementor-top-column"
            data-element_type="column"
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div class="elementor-element elementor-element-419171e elementor-widget elementor-widget-image">
                  <div class="elementor-widget-container">
                    {/* <div class="elementor-image">
											<a data-elementor-open-lightbox="" href="index.html">
												<img
													width="228"
													height="54"
													src={Logo}
													class="attachment-full size-full"
													alt=""
												/>
											</a>
										</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-element elementor-element-60aa1ba elementor-column elementor-col-50 elementor-top-column"
            data-element_type="column"
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-bdc46b3 elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-avante-navigation-menu"
                  data-element_type="widget"
                  data-widget_type="avante-navigation-menu.default"
                >
                  <div class="elementor-widget-container">
                    <div class="themegoods-navigation-wrapper menu_style1">
                      <div class="menu-main-menu-container">
                        <ul id="nav_menu40" class="nav">
                          <li class="menu-item menu-item-has-children current-menu-item">
                            <a href="/">Home</a>
                          </li>

                          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow ">
                            <a href="#">About Us</a>
                            <ul class="sub-menu">
                              <li class="menu-item">
                                <a href="/AboutUs">About Us</a>
                              </li>
                              <li class="menu-item">
                                <a href="/AboutUs/OurExpertsAndTeam">
                                  Our Experts and Team
                                </a>
                              </li>
                              <li class="menu-item">
                                <a href="/AboutUs/OurClient">Our Clients</a>
                              </li>
                              <li class="menu-item">
                                <a
                                  href="https://drive.google.com/file/d/1_cQMkFsZJEs4WZbYv6TTrv9bIr9IneH1/view?usp=sharing"
                                  target="_blank"
                                >
                                  Company Profile
                                </a>
                              </li>
                              <li class="menu-item">
                                <a
                                  href="https://drive.google.com/drive/folders/1NOhz_UjQVQ_e7OZ24XVlCGHLbcVXZ62w?usp=sharing"
                                  target="_blank"
                                >
                                  Catalogue
                                </a>
                              </li>
                              <li class="menu-item">
                                <a href="/AboutUs/Careers">Careers</a>
                              </li>
                            </ul>
                          </li>

                          <li className=" menu-item menu-item-type-custom menu-item-object-custom elementor-megamenu megamenu arrow">
                            <a href="#">Services</a>
                            <ul className="elementor-megamenu-wrapper">
                              <div
                                data-elementor-type="wp-post"
                                data-elementor-id={6189}
                                className="elementor elementor-6189"
                                data-elementor-settings="[]"
                              >
                                <div className="elementor-inner">
                                  <div className="elementor-section-wrap">
                                    <section
                                      className="elementor-element elementor-element-b5db8c5 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="b5db8c5"
                                      data-element_type="section"
                                      data-settings='{"background_background":"classic"}'
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img1}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Bioavailability / <br />
                                                        Bioequivalence Studies
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        More than 300 completed
                                                        studies for Indonesian &
                                                        international sponsors
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/BABEStudies"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img3}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Clinical Trial <br />
                                                        Services
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        In-house trials as well
                                                        as collaboration with
                                                        reputable investigators
                                                        and hospitals
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/ClinicalTrials"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img4}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Contract Analysis
                                                        <br />
                                                        Services
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        We offer various
                                                        analytical testings
                                                        including chemical,
                                                        microbiology, physical,
                                                        and other test
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/ContractAnalysis"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img2}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Other <br />
                                                        Services
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        Other services we
                                                        provide
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/Others"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </li>

                          <li class="menu-item menu-item-has-children arrow">
                            <a href="#">Experience</a>
                            <ul class="sub-menu">
                              <li class="menu-item">
                                <a href="/Experiences/BABEStudies">
                                  BA/BE Studies
                                </a>
                              </li>
                              <li class="menu-item">
                                <a href="/Experiences/ClinicalTrials">
                                  Clinical Trials
                                </a>
                              </li>
                              <li class="menu-item">
                                <a href="/Experiences/ContractAnalysis">
                                  Contract Analysis
                                </a>
                              </li>
                            </ul>
                          </li>

                          {/* <li class="megamenu col4 menu-item menu-item-has-children">
                            <a href="/Gallery">Facilities</a>
                          </li> */}

                          <li className="menu-item menu-item-has-children arrow">
                            <a href="#">Gallery</a>
                            <ul className="sub-menu">
                              <li className="menu-item">
                                <a href="/Gallery">Facilities</a>
                              </li>
                              <li className="menu-item">
                                <a href="/Gallery/Publication">Publication</a>
                              </li>
                              <li className="menu-item">
                                <a
                                  href="https://vr-tour-pharmametriclabs.netlify.app/"
                                  target="_blank"
                                >
                                  VR Gallery
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="menu-item menu-item-has-children current-menu-item">
                            <a href="/ContactUs">Contact Us</a>
                            {/* <ul className="sub-menu">
                              <li className="menu-item">
                                <a href="/HelpCenter/ContactUs">Contact Us</a>
                              </li>
                              <li className="menu-item">
                                <a href="/HelpCenter/Tracking">Tracking</a>
                              </li>
                            </ul> */}
                          </li>
                          <li class="menu-item menu-item-has-children current-menu-item">
                            <a href="/News">News</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-2f86783 elementor-shape-circle elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-social-icons"
                  data-element_type="widget"
                  data-widget_type="social-icons.default"
                >
                  <div class="elementor-widget-container" />
                </div>
                <div
                  class="elementor-element elementor-element-4639a93 elementor-widget__width-auto elementor-widget elementor-widget-avante-search"
                  data-element_type="widget"
                  data-widget_type="avante-search.default"
                >
                  <div class="elementor-widget-container">
                    <div class="avante-search-icon">
                      <a data-open="tg_search_4639a93" href="/SearchBar">
                        <i
                          aria-hidden="true"
                          class="fas fa-search iconSizeHome"
                        />
                      </a>
                    </div>

                    {/* <div id="tg_search_4639a93" class="avante-search-wrapper">
											<div class="avante-search-inner">
												<form
													id="tg_search_form_4639a93"
													class="tg_search_form autocomplete_form"
													method="get"
													action="#"
													data-result="autocomplete_4639a93"
													data-open="tg_search_4639a93"
												>
													<div class="input-group">
														<input
															id="s"
															name="s"
															placeholder="Search for anything"
															autocomplete="off"
															value=""
														/>
														<span class="input-group-button">
															<button aria-label="Search for anything" type="submit">
																<i aria-hidden="true" class="fas fa-search" />
															</button>
														</span>
													</div>
													<br class="clear" />
													<div
														id="autocomplete_4639a93"
														class="autocomplete"
														data-mousedown="false"
													/>
												</form>
											</div>
										</div> */}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-fbb8940 elementor_mobile_nav elementor-widget__width-auto elementor-hidden-desktop elementor-view-default elementor-widget elementor-widget-icon"
                  data-element_type="widget"
                  data-widget_type="icon.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-icon-wrapper">
                      <a
                        class="elementor-icon "
                        href="javascript:;"
                        style={{ color: "#00623d", width: "26px" }}
                      >
                        {/* <i aria-hidden="true" class="fas fa-ellipsis-v" /> */}
                        <AiOutlineBars
                          className="iconSize2"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Navbar
