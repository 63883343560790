import React from "react"
import NewsSlider from "../../sliders/NewsSlider"

const UpdatedNews = ({ newsContent }) => {
  return (
    <section className="slider" style={{ marginTop: "2em" }}>
      <div className="container">
        <h2 className="latest-news-red">Latest News</h2>
        <h2 className="latest-news-title">Our Latest News & Events</h2>
        <NewsSlider contents={newsContent ? newsContent.nodes : []} />
      </div>
    </section>
  )
}

export default UpdatedNews
